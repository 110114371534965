import { Box, Divider, List, ListItemButton, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router';
import HeaderMenuDrawerListItem, {
  HeaderMenuDrawerListItemProps,
} from './HeaderMenuDrawerListItem';

export interface HeaderMenuDrawerListProps {
  menu: HeaderMenuDrawerListItemProps[];
  onClose: (event: React.KeyboardEvent | React.MouseEvent) => void;
}

export default function HeaderMenuDrawerList(props: HeaderMenuDrawerListProps) {
  const navigate = useNavigate();
  const { onClose, menu } = props;

  return (
    <Box sx={{ width: 250 }} role="presentation" onClick={onClose} onKeyDown={onClose}>
      <ListItemButton>
        <ListItemText primary="Home" onClick={() => navigate('/')} />
      </ListItemButton>
      <Divider />
      <List>
        {menu.map((m) => (
          <HeaderMenuDrawerListItem text={m.text} url={m.url} key={m.text} />
        ))}
      </List>
    </Box>
  );
}
