import { EtsyTokenResponse } from '@pm/types';
import { Tokens } from '@pm/etsy-ts';

export function mapEtsyTokenToToken(etsyToken: EtsyTokenResponse): Tokens {
  return {
    accessToken: etsyToken.access_token,
    refreshToken: etsyToken.refresh_token,
    expiresIn: etsyToken.expires_in,
    tokenType: etsyToken.token_type,
  };
}
