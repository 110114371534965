import { indexOf } from './indexOf';

/**
 *
 * @param arr
 * @param isCaseInsensitive
 * @returns
 * @deprecated should use Set
 */
export function removeDuplicate(arr: (number | string)[], isCaseInsensitive?: boolean) {
  const result: (number | string)[] = [];
  arr.forEach((v) => {
    if (indexOf(result, v, isCaseInsensitive) < 0) result.push(v);
  });
  return result;
}
