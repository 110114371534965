import { IListingImage } from '@pm/etsy-ts';
import { Account } from '@pm/types';
import { useEffect, useState } from 'react';
import getEtsyShopListingImage from '../logic/firebase/getEtsyShopListingImage';

export function useEtsyGetShopListingImage(
  account: Account,
  listingId?: number,
  listingImageId?: number,
) {
  // console.debug('useEtsyGetShopListingImage');
  const [data, setData] = useState<IListingImage | undefined>();

  useEffect(() => {
    const fetchData = async () => {
      if (!account || !listingId || !listingImageId) {
        setData(undefined);
        return;
      }

      // get data
      const result = await getEtsyShopListingImage(account.loginName, listingId, listingImageId);
      setData(result);
    };
    fetchData();
  }, [account, listingId, listingImageId]);
  return data;
}
