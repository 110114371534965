import { Typography } from '@mui/material';

export interface IHeaderTitleProps {
  title: string;
}

export default function HeaderTitle(props: IHeaderTitleProps) {
  const { title } = props;
  return <Typography variant="h6">{title}</Typography>;
}
