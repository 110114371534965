import { AppBar, Box, Toolbar } from '@mui/material';
import { IAuthState } from '../../hooks/useAuth';
import { menu } from '../../setup/menu';
import AccountMenuComponent, {
  IAccountMenuComponentProps,
} from '../accountMenu/AccountMenuComponent';
import HeaderAccount from './HeaderAccount';
import HeaderMenu from './HeaderMenu';
import HeaderTitle, { IHeaderTitleProps } from './HeaderTitle';
import HeaderAccountAdd from './HeaderAccountAdd';

export interface IHeaderOrganismProps extends IHeaderTitleProps, IAccountMenuComponentProps {
  auth: IAuthState;
}

export default function HeaderOrganism(props: IHeaderOrganismProps) {
  const { title, auth, accounts, account, setAccount } = props;

  if (!auth) return null;

  return (
    <AppBar position="static">
      <Toolbar>
        {auth && <HeaderMenu menu={menu} />}
        <HeaderTitle title={title} />

        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: { xs: 'flex', sm: 'flex' } }}>
          <AccountMenuComponent accounts={accounts} account={account} setAccount={setAccount} />
          <HeaderAccountAdd />
          <HeaderAccount user={auth} />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
