import { httpsCallable } from '@firebase/functions';
import { IListingImage } from '@pm/etsy-ts';
import { firebaseFunction } from '../../setup/firebase';

export default async function getEtsyShopListingImage(
  shopName: string,
  listingId: number,
  listingImageId: number,
) {
  try {
    const result = await httpsCallable<
      { shopName: string; listingId: number; listingImageId: number },
      IListingImage
    >(
      firebaseFunction,
      'getShopListingImage',
    )({ shopName, listingId, listingImageId });

    return result.data;
  } catch (e) {
    return undefined;
  }
}
