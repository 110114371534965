/* eslint-disable import/no-extraneous-dependencies */
import { getAuth } from '@firebase/auth';
import { getFirestore } from '@firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from '@firebase/functions';
import { FirebaseOptions, initializeApp } from 'firebase/app';
import getEnv, { EnvEnum } from '../logic/util/getEnv';

const config: FirebaseOptions = {
  apiKey: 'AIzaSyDq4q-SBRSh3lAojGvSMBhjRYRrEn4vWO4',
  authDomain: 'etsy-d5e0c.firebaseapp.com',
  projectId: 'etsy-d5e0c',
  storageBucket: 'etsy-d5e0c.appspot.com',
  messagingSenderId: '463048729168',
  appId: '1:463048729168:web:2fe8b65cb24f74a69b6755',
  measurementId: 'G-B09R0KPZWB',
};

const firebaseApp = initializeApp(config);

export const firebaseFunction = getFunctions(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);
export const firebaseFirestore = getFirestore(firebaseApp);

firebaseFunction.region = 'europe-west2';

if (getEnv() === EnvEnum.DEV) {
  console.log('using emulator');
  connectFunctionsEmulator(firebaseFunction, 'localhost', 5001);
}
