import { DataGrid, GridRowParams } from '@mui/x-data-grid';
import { IShopReceipt } from '@pm/etsy-ts';
import { analysisTableColumns } from './columns';

export interface ReceiptTableProps {
  receipts: IShopReceipt[];
  pageSize: number;
  setPageSize: (n: number) => void;
  pageNumber: number;
  setPageNumber: (n: number) => void;
  rowCount: number;
  setReceipt: (receipt: IShopReceipt) => void;
}

export default function ReceiptTable(props: ReceiptTableProps) {
  const { receipts, pageSize, setPageSize, pageNumber, setPageNumber, rowCount, setReceipt } =
    props;

  // descending closed position
  // const descPositions = positionSortByCloseTs(closedPositions, false);
  return (
    <DataGrid<IShopReceipt>
      sx={{
        minHeight: 600,
        width: '90%',
        height: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 2,
      }}
      rows={receipts}
      columns={analysisTableColumns()}
      paginationMode="server"
      page={pageNumber}
      onPageChange={setPageNumber}
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
      rowCount={rowCount}
      rowsPerPageOptions={[10, 25, 50, 100]}
      disableSelectionOnClick
      getRowId={(row) => row.receipt_id as number}
      getRowHeight={() => 80}
      onRowClick={(param: GridRowParams<IShopReceipt>) => {
        setReceipt(param.row);
      }}
    />
  );
}
