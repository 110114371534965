import { IShopReceipt } from '@pm/etsy-ts';
import { UtilName } from '@pm/utils';
import { HermesCsv, initHermes } from '@pm/types';

export function mapShopReceiptToHermesCsv(receipt: IShopReceipt): HermesCsv {
  const names = UtilName.splitFirstLast(receipt.name || '');
  return {
    ...initHermes,
    First_name: names.first || '',
    Last_name: names.last || '',
    Address_line_1: receipt.first_line || '',
    Address_line_2: receipt.second_line || '',
    Address_line_3: receipt.city || '',
    Address_line_4: receipt.state || '',
    Postcode: receipt.zip || '',
    Reference: receipt.receipt_id?.toString() || '',
  };
}
