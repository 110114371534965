import { IShopReceipts } from '@pm/etsy-ts';
import { Account } from '@pm/types';
import { useEffect, useState } from 'react';
import getEtsyReceipts from '../logic/firebase/getEtsyReceipts';

const defaultData: Required<IShopReceipts> = { results: [], count: 0 };

export default function useEtsyReceiptList(
  account: Account | undefined,
  offset: number,
  limit: number,
): Required<IShopReceipts> {
  const [data, setData] = useState<Required<IShopReceipts>>(defaultData);

  useEffect(() => {
    const fetchData = async () => {
      if (!account) {
        setData(defaultData);
        return;
      }

      // get data
      const result = await getEtsyReceipts(account.loginName, { offset, limit });

      setData(result ? { ...defaultData, ...result } : { ...defaultData });
    };
    fetchData();
  }, [account, offset, limit]);
  return data;
}
