import { UtilCalculate } from '@pm/utils';

export const deliveryNoteSettings = {
  sizingMm: {
    paper: {
      width: 210,
      height: 297,
      paddingTop: 10,
      paddingLeft: 10,
      paddingBottom: 10,
      paddingRight: 10,
    },
    label: { width: 110, height: 80, marginLeft: 0, marginTop: 0 },
  },
  dpi: 90,
};

export const deliveryNoteHeightMm =
  deliveryNoteSettings.sizingMm.paper.height -
  deliveryNoteSettings.sizingMm.paper.paddingTop -
  deliveryNoteSettings.sizingMm.paper.paddingBottom;

export const deliveryNoteWidthMm =
  deliveryNoteSettings.sizingMm.paper.width -
  deliveryNoteSettings.sizingMm.paper.paddingLeft -
  deliveryNoteSettings.sizingMm.paper.paddingRight;

export const printHeightMm =
  deliveryNoteSettings.sizingMm.paper.height -
  deliveryNoteSettings.sizingMm.paper.paddingTop -
  deliveryNoteSettings.sizingMm.paper.paddingBottom;

export const printWidthMm =
  deliveryNoteSettings.sizingMm.paper.width -
  deliveryNoteSettings.sizingMm.paper.paddingLeft -
  deliveryNoteSettings.sizingMm.paper.paddingRight;

export const defaultPaperHeightPx = UtilCalculate.convertMmToPx(
  deliveryNoteHeightMm,
  deliveryNoteSettings.dpi,
);
export const defaultPaperWidthPx = UtilCalculate.convertMmToPx(
  deliveryNoteWidthMm,
  deliveryNoteSettings.dpi,
);

export const careInstruction = [
  'Separate light and dark colour clothes especially in the first couple of washes',
  'Wash inside out in cold water (30degC) using mild detergent',
  'Dry on low heat setting or hang dry',
  'Do NOT bleach or dry clean',
  'Only iron inside out',
];

export const defaultReturnAddress = [
  'Slaker Cheung',
  '54 Alcantara Crescent, Southampton',
  'SO14 3HR, UK',
];
