import { number, object, output, string } from 'zod';

export const zAccount = object({
  loginName: string(),
  userId: number(),
  shopId: number(),
});
/**
 * Account/shop of user
 */
export type Account = output<typeof zAccount>;

/**
 * User record of multiple accounts
 */
export type Accounts = Record<string, Account>;
export const initAccounts: Accounts = {};

export function createAccount(loginName: string, userId: number, shopId: number): Account {
  return {
    loginName,
    userId,
    shopId,
  };
}
