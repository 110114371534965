import { Close, Done } from '@mui/icons-material';
import { Box } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { IShopReceipt } from '@pm/etsy-ts';

export const messageColumn: GridColDef<IShopReceipt> = {
  field: 'message_from_buyer',
  headerName: 'Message from buyer',
  align: 'left',
  headerAlign: 'center',
  width: 150,
  renderCell: (params: GridRenderCellParams<string | null, IShopReceipt, any>) => {
    const val = params.value;
    return <Box>{val ? <Done /> : <Close />}</Box>;
  },
};
