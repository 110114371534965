import { literal, number, object, output, string } from 'zod';

export const zEtsyTokenResponse = object({
  access_token: string(),
  token_type: literal('Bearer'),
  expires_in: number().min(3000),
  refresh_token: string(),
});

export type EtsyTokenResponse = output<typeof zEtsyTokenResponse>;
