import { httpsCallable } from '@firebase/functions';
import { IShopReceipt } from '@pm/etsy-ts';
import { firebaseFunction } from '../../setup/firebase';

export default async function getEtsyReceipt(shopName: string, receiptId: number) {
  try {
    const result = await httpsCallable<{ shopName: string; receiptId: number }, IShopReceipt>(
      firebaseFunction,
      'getShopReceipt',
    )({ shopName, receiptId });

    return result.data;
  } catch (e) {
    return undefined;
  }
}
