import { Drawer } from '@mui/material';
import HeaderMenuDrawerList, { HeaderMenuDrawerListProps } from './HeaderMenuDrawerList';

export interface HeaderMenuDrawerProps extends HeaderMenuDrawerListProps {
  open: boolean;
}

export default function HeaderMenuDrawer(props: HeaderMenuDrawerProps) {
  const { open, onClose, menu } = props;
  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <HeaderMenuDrawerList onClose={onClose} menu={menu} />
    </Drawer>
  );
}
