/**
 * array index of function but also allow case insensitive search
 * @param arr array to search for
 * @param value value to compare
 * @param isCaseInsensitive define whether search is case sensitive or not
 */
export function indexOf(
  arr: (number | string)[],
  value: number | string,
  isCaseInsensitive = false,
): number {
  const newValue = typeof value === 'string' && isCaseInsensitive ? value.toLowerCase() : value;
  const newArr = isCaseInsensitive
    ? arr.map((v) => (typeof v === 'string' ? v.toLowerCase() : v))
    : arr;
  return newArr.indexOf(newValue);
}
