import { Menu as MenuIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useState } from 'react';
import HeaderMenuDrawer, { HeaderMenuDrawerProps } from './HeaderMenuDrawer';

export type IHeaderMenuProps = Pick<HeaderMenuDrawerProps, 'menu'>;

export default function HeaderMenu(props: IHeaderMenuProps) {
  const [openState, setOpenState] = useState(false);

  const { menu } = props;

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setOpenState(open);
  };

  return (
    <IconButton
      edge="start"
      color="inherit"
      aria-label="menu"
      sx={{ mr: 2 }}
      onClick={toggleDrawer(!openState)}
    >
      <MenuIcon />
      <HeaderMenuDrawer open={openState} onClose={toggleDrawer(false)} menu={menu} />
    </IconButton>
  );
}
