import { GridColDef } from '@mui/x-data-grid';
// import { closePriceColumn } from './closePriceColumn';
import { countryColumn } from './countryColumn';
import { giftMessageColumn } from './giftMessage';
import { giftWrapColumn } from './giftWrap';
import { messageColumn } from './message';
import { nameColumn } from './name';
import { orderDateColumn } from './orderDate';

export const analysisTableColumns = () =>
  [
    nameColumn,
    countryColumn,
    orderDateColumn,
    messageColumn,
    giftMessageColumn,
    giftWrapColumn,
  ] as GridColDef[];
