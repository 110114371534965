import { Grid, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { IShopReceipt } from '@pm/etsy-ts';

export const orderDateColumn: GridColDef<IShopReceipt> = {
  field: 'created_timestamp',
  headerName: 'Order date',
  align: 'left',
  headerAlign: 'center',
  width: 150,
  renderCell: (params: GridRenderCellParams<number, IShopReceipt, any>) => {
    const val = params.value || 0;
    const dt = dayjs(val * 1000);
    return (
      <Grid container wrap="wrap">
        <Typography variant="subtitle2">{dt.format('YYYY-MM-DD')}</Typography>
      </Grid>
    );
  },
};
