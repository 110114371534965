/**
 * swap index of element in array
 * @param arr array to be processed on
 * @param index1 index of first element
 * @param index2 index of second element
 */
export function swapElement<T>(arr: T[] | undefined, index1: number, index2: number) {
  if (!arr) return arr;
  if (index1 >= arr.length || index2 >= arr.length || index1 < 0 || index2 < 0) return arr;
  const dupe = [...arr];
  const ele = dupe[index1];
  dupe[index1] = dupe[index2] as T;
  dupe[index2] = ele as T;
  return dupe;
}
