import { Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { IShopReceipt } from '@pm/etsy-ts';

export interface DeliveryNoteHeaderProps {
  receipt: IShopReceipt;
}

export default function DeliveryNoteHeader({ receipt }: DeliveryNoteHeaderProps) {
  return (
    <Grid
      className="DeliveryNoteHeader"
      container
      direction="column"
      sx={{
        marginBottom: '10px',
      }}
    >
      <Grid item>
        <Typography
          gutterBottom
          sx={{
            textAlign: 'center',
          }}
        >
          Email: {receipt.seller_email}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-evenly" direction="row">
          <Grid item>
            <Typography variant="subtitle1" display="inline">
              Reference: {receipt.receipt_id}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              Date: {dayjs.unix(receipt.create_timestamp || 0).format('DD-MMM-YYYY')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
