export interface ITranslation {
  find: string;
  output: string;
}

/**
 * find string in dictionary and return output, otherwise return original string
 * @param dictionary
 * @param msg
 */
export function translate(dictionary: ITranslation[], msg: string) {
  const result = dictionary.filter((field) => msg.indexOf(field.find) >= 0);
  const firstItem = result.at(0);

  return firstItem ? firstItem.output : msg;
}
