import { Box, Button, DialogActions } from '@mui/material';

export interface DeliveryButtonsProps {
  handleClose: () => void;
}

export default function DeliveryButtons(props: DeliveryButtonsProps) {
  const { handleClose } = props;

  return (
    <Box displayPrint="none">
      <DialogActions>
        <Button onClick={() => window.print()}>Print</Button>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Box>
  );
}
