import { Add } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import getEtsySso from '../../logic/firebase/getEtsySso';

async function popupForLogin() {
  const url = await getEtsySso();
  window.open(url.data as string, 'popup', 'popup=true');
}

export default function HeaderAccountAdd() {
  return (
    <IconButton
      aria-label="Link account"
      aria-haspopup="true"
      color="inherit"
      onClick={popupForLogin}
      sx={{ marginRight: 0 }}
    >
      <Add />
    </IconButton>
  );
}
