import { Accounts, CollectionEnum, initAccounts } from '@pm/types';
import {
  CollectionReference,
  DocumentSnapshot,
  collection,
  doc,
  onSnapshot,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { firebaseFirestore } from '../setup/firebase';

const handleChange =
  (setAccounts: (acc: Accounts) => void) => (querySnapshot: DocumentSnapshot<Accounts>) => {
    const result = querySnapshot.data();
    setAccounts(result || initAccounts);
  };

export default function useSubscribeAccounts(uid?: string): Accounts {
  const [accounts, setAccounts] = useState<Accounts>(initAccounts);

  useEffect(() => {
    if (!uid) {
      setAccounts(initAccounts);
      return undefined;
    }

    const colRef = collection(
      firebaseFirestore,
      CollectionEnum.ACCOUNTS,
    ) as CollectionReference<Accounts>;

    const docRef = doc(colRef, uid);
    const unsubscribe = onSnapshot(docRef, handleChange(setAccounts));

    return () => {
      unsubscribe();
    };
  }, [uid]);

  return accounts;
}
