import { Dialog, Grid, Typography } from '@mui/material';
import { GoogleAuthProvider } from 'firebase/auth';
import { firebaseAuth } from '../../setup/firebase';
import FirebaseAuth from './FirebaseAuth';

function LoginPage() {
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // signInSuccessUrl: '/signedIn',
    // tosUrl:'/tos',
    // privacyPolicyUrl:'/privacy',
    signInOptions: [GoogleAuthProvider.PROVIDER_ID],
  };

  return (
    <Dialog maxWidth="sm" open sx={{ '& .MuiPaper-root': { minHeight: 300, padding: 5 } }}>
      <Grid container justifyContent="center" direction="column" sx={{ flex: 1 }}>
        <Grid item sx={{ textAlign: 'center', flex: 1 }}>
          <Typography variant="h5">Please login</Typography>
        </Grid>
        <Grid item sx={{ flex: 1 }}>
          <FirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseAuth} />
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default LoginPage;
