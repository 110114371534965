/**
 * the highest item number in the page, index starts with 1, min 0 when total count 0
 * @param itemPerPage
 * @param totalCount
 * @param page
 */
export function maxItem(itemPerPage: number, totalCount: number, page: number) {
  const newItemPerPage = itemPerPage < 1 ? 1 : itemPerPage;
  const newPage = page < 1 ? 1 : page;
  const newTotalCount = totalCount < 0 ? 0 : totalCount;
  const result = newPage * newItemPerPage;
  return result <= newTotalCount ? result : newTotalCount;
}
