export interface HermesCsv {
  Address_line_1: string;
  Address_line_2: string;
  Address_line_3: string;
  Address_line_4: string;
  Postcode: string;
  First_name: string;
  Last_name: string;
  Email: string;
  ['Weight(Kg)']: string;
  ['Compensation(£)']: string;
  ['Signature(y/n)']: string;
  Reference: string;
  Contents: string;
  ['Parcel_value(£)']: string;
  Delivery_phone: string;
  Delivery_safe_place: string;
  Delivery_instructions: string;
  Service: string;
}

export const initHermes: HermesCsv = {
  Address_line_1: '',
  Address_line_2: '',
  Address_line_3: '',
  Address_line_4: '',
  Postcode: '',
  First_name: '',
  Last_name: '',
  Email: '',
  'Weight(Kg)': '0.5',
  'Compensation(£)': '20',
  'Signature(y/n)': 'n',
  Reference: '',
  Contents: '',
  'Parcel_value(£)': '',
  Delivery_phone: '',
  Delivery_safe_place: '',
  Delivery_instructions: '',
  Service: '',
};

export const hermesHeader = Object.keys(initHermes);
