import { GridColDef } from '@mui/x-data-grid';
import { IShopReceipt } from '@pm/etsy-ts';

export const nameColumn: GridColDef<IShopReceipt> = {
  field: 'name',
  headerName: 'Name',
  align: 'left',
  headerAlign: 'center',
  width: 150,
};
