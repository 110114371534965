export enum EnvEnum {
  DEV = 'development',
  STAGE = 'staging',
  PROD = 'production',
}

export default function getEnv(): EnvEnum {
  const value = process.env['REACT_APP_ENV'] || EnvEnum.PROD;
  if (Object.values(EnvEnum).includes(value as EnvEnum)) return value as EnvEnum;
  return EnvEnum.PROD;
}
