import { DialogContent, useMediaQuery } from '@mui/material';
import { UtilCalculate } from '@pm/utils';
import DeliveryNote, { DeliveryNoteProps } from './DeliveryNote';
import {
  deliveryNoteHeightMm,
  deliveryNoteSettings,
  deliveryNoteWidthMm,
} from './DeliverySettings';

export type DeliveryBodyProps = Pick<DeliveryNoteProps, 'receipt' | 'account'>;

export default function DeliveryBody(props: DeliveryBodyProps) {
  const { receipt, account } = props;
  const matchesPrint = useMediaQuery('print');

  return (
    <DialogContent
      sx={{
        height: UtilCalculate.convertMmToPx(deliveryNoteHeightMm, deliveryNoteSettings.dpi),
        width: '100%',
        padding: 0,
        margin: 0,
      }}
      style={
        matchesPrint
          ? {
              overflow: 'hidden',
              scrollbarWidth: 'none',
            }
          : {}
      }
    >
      <DeliveryNote
        account={account}
        receipt={receipt}
        paperHeightPx={UtilCalculate.convertMmToPx(deliveryNoteHeightMm, deliveryNoteSettings.dpi)}
        paperWidthPx={UtilCalculate.convertMmToPx(deliveryNoteWidthMm, deliveryNoteSettings.dpi)}
      />
    </DialogContent>
  );
}
