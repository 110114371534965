import { Box, Dialog, DialogTitle } from '@mui/material';
import { UtilCalculate } from '@pm/utils';
import DeliveryBody, { DeliveryBodyProps } from './DeliveryBody';
import DeliveryButtons, { DeliveryButtonsProps } from './DeliveryButtons';
import { deliveryNoteSettings, deliveryNoteWidthMm } from './DeliverySettings';

/// /////////////interface
export interface DeliveryComponentProps extends DeliveryBodyProps, DeliveryButtonsProps {
  open?: boolean;
}
export default function DeliveryComponent(props: DeliveryComponentProps) {
  const { open, handleClose, receipt, account } = props;

  if (receipt === undefined || open === false) return null;

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="delivery note"
      open
      fullWidth
      maxWidth="lg"
      sx={{
        '& .MuiDialog-paper': {
          width: UtilCalculate.convertMmToPx(deliveryNoteWidthMm, deliveryNoteSettings.dpi) + 20,
        },
      }}
    >
      {/* title */}
      <Box displayPrint="none">
        <DialogTitle>Delivery note</DialogTitle>
      </Box>

      {/* actual delivery note */}
      <DeliveryBody receipt={receipt} account={account} />

      {/* buttons */}
      <DeliveryButtons handleClose={handleClose} />
    </Dialog>
  );
}
