import { httpsCallable } from '@firebase/functions';
import { IGetShopReceiptsParams, IShopReceipts } from '@pm/etsy-ts';
import { firebaseFunction } from '../../setup/firebase';

export default async function getEtsyReceipts(
  shopName: string,
  options: Omit<IGetShopReceiptsParams, 'shopId'>,
) {
  try {
    const result = await httpsCallable<
      { shopName: string; options: Omit<IGetShopReceiptsParams, 'shopId'> },
      IShopReceipts
    >(
      firebaseFunction,
      'getShopReceipts',
    )({ shopName, options });
    return result.data;
  } catch (e) {
    return undefined;
  }
}
