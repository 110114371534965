import { Grid, Paper, Typography } from '@mui/material';
import { isGiftWrap } from '@pm/helper';
import { Account } from '@pm/types';
import { IShopReceipt } from '@pm/etsy-ts';
import countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import DeliveryNoteAddress from './DeliveryNoteAddress';
import DeliveryNoteBanner from './DeliveryNoteBanner';
import DeliveryNoteHeader from './DeliveryNoteHeader';
import DeliveryNoteTransactions from './DeliveryNoteTransactions';
import { careInstruction, printHeightMm, printWidthMm } from './DeliverySettings';

countries.registerLocale(en);

export interface DeliveryNoteProps {
  receipt?: IShopReceipt;
  paperHeightPx: number;
  paperWidthPx: number;
  account?: Account;
}

/**
 * The printed part of the delivery note, where the top left is address label corner
 * assume left/right and top/bottom margin the same
 */
export default function DeliveryNote({
  receipt,
  account,
  paperHeightPx,
  paperWidthPx,
}: DeliveryNoteProps) {
  // const transactions = useEtsyReceiptTransaction(account, receipt);

  if (!receipt || !account) return <> </>;

  const deliveryAddress = [
    receipt.name || '',
    receipt.first_line || '',
    (receipt.second_line && receipt.second_line.length > 0 ? `${receipt.second_line}, ` : '') +
      receipt.city,
    (receipt.state && receipt.state.length > 0 ? `${receipt.state}, ` : '') + receipt.zip,
    receipt.country_iso ? countries.getName(receipt.country_iso, 'en') : '',
  ];

  return (
    <Paper
      sx={{
        padding: 0,
        margin: 0,
        overflow: 'hidden',
      }}
      id="deliveryNote"
      style={{ width: paperWidthPx, height: paperHeightPx }}
      elevation={0}
    >
      <Grid container direction="column">
        {/* address column */}
        <Grid
          item
          sx={{
            overflow: 'hidden',
            margin: 0,
            padding: 0,
          }}
          style={{
            height: (80 / printHeightMm) * paperHeightPx,
            marginTop: (25 / printHeightMm) * paperHeightPx,
          }}
        >
          <Grid
            container
            direction="row"
            alignContent="center"
            wrap="nowrap"
            style={{ height: (80 / printHeightMm) * paperHeightPx }}
          >
            <Grid
              item
              sx={{
                boxSizing: 'border-box',
                paddingLeft: '10px',
                paddingRight: '10px',
                overflow: 'hidden',
              }}
              style={{ width: (110 / printWidthMm) * paperWidthPx }}
              // alignItems="center"
            >
              <DeliveryNoteAddress deliveryAddress={deliveryAddress} />
            </Grid>
            <Grid
              item
              style={{
                paddingLeft: 30,
                width: (80 / printWidthMm) * paperWidthPx,
              }}
            >
              <Typography variant="subtitle1">Care Instruction</Typography>
              {careInstruction.map((c, i) => (
                <Typography variant="body2" key={`careInstruction-${c.substring(0, 10)}`}>{`${
                  i + 1
                }. ${c}`}</Typography>
              ))}
            </Grid>
          </Grid>
        </Grid>

        {/* banner column */}
        <Grid item style={{ height: (25 / printHeightMm) * paperHeightPx }}>
          <DeliveryNoteBanner shop={account.loginName.toLowerCase()} />
        </Grid>

        {/* header column */}
        <Grid item>
          <DeliveryNoteHeader receipt={receipt} />
        </Grid>

        {/* products column */}
        <Grid item style={{ height: (115 / printHeightMm) * paperHeightPx }}>
          <DeliveryNoteTransactions account={account} transactions={receipt.transactions || []} />
        </Grid>

        {/* message column */}
        <Grid item>
          {receipt.message_from_buyer && receipt.message_from_buyer.length > 0 && (
            <Typography>Message</Typography>
          )}
          {receipt.gift_message && receipt.gift_message.length > 0 && (
            <Typography>Gift message</Typography>
          )}
          {isGiftWrap(receipt) ? <Typography>Gift wrap</Typography> : null}
        </Grid>
      </Grid>
    </Paper>
  );
}
