/**
 * encode html entity - actually encodes everything including a-z
 * @param str string to encode
 */
export function encodeHtmlEntity(str: string) {
  const buf = [];
  // eslint-disable-next-line no-plusplus
  for (let i = str.length - 1; i >= 0; i--) {
    buf.unshift(['&#', str.charCodeAt(i), ';'].join(''));
  }
  return buf.join('');
}
