/* eslint-disable @typescript-eslint/default-param-last */
import html2canvas from 'html2canvas';
import { jsPDF as JsPDF } from 'jspdf';

// generate pdf with 1cm margin on all side
export async function print(
  elementId: string,
  paper = 'a4',
  filename = 'download.pdf',
  imgWidthMm: number,
  imgHeightMm: number,
  _dpi = 72,
) {
  const input = document.getElementById(elementId);
  if (!input) return false;

  const canvas = await html2canvas(input);
  const imgData = canvas.toDataURL('image/png');
  const pdf = new JsPDF('p', 'mm', paper);

  // calculate location
  // const leftEdgeMm =
  //   (pdf.internal.pageSize.getWidth() -
  //     Util.Calculate.convertPxToMm(canvas.width, dpi)) /
  //   2;
  // fit image to fill full page size
  pdf.addImage(
    imgData,
    'JPEG',
    6,
    5,
    imgWidthMm,
    imgHeightMm,
    // pdf.internal.pageSize.getWidth(),
    // (pdf.internal.pageSize.getWidth() / canvas.width) * canvas.height
  );
  pdf.save(filename);
  return true;
}
