import { Grid, TextField } from '@mui/material';
import { filterEtsyShopReceiptShippingStatus, mapShopReceiptToHermesCsv } from '@pm/helper';
import { Account, initHermes } from '@pm/types';
import dayjs from 'dayjs';
import { IShopReceipt } from '@pm/etsy-ts';
import { useState } from 'react';
import { CSVLink } from 'react-csv';

export interface IReceiptHeaderProps {
  account: Account;
  receipts: IShopReceipt[];
  onClickSearchReceiptId: (id: string) => void;
}

export default function ReceiptHeader(props: IReceiptHeaderProps) {
  // styles
  const linkSty = `MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary`;

  // props
  const { receipts, account, onClickSearchReceiptId } = props;

  const [searchReceiptId, setSearchReceiptId] = useState('');

  // all related receipts
  const unshippedReceipts = receipts.filter(filterEtsyShopReceiptShippingStatus(false));

  if (!account) return null;
  const emptyHermesHeader = Object.keys(initHermes);
  return (
    <Grid
      container
      direction="row"
      spacing={1}
      sx={{ margin: 2 }}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <CSVLink
          data={unshippedReceipts.map(mapShopReceiptToHermesCsv)}
          filename={`hermes-${account.loginName.toLowerCase()}-${dayjs().format('YYYY-MM-DD')}.csv`}
          className={linkSty}
          title="Download CSV for unshipped items for import to Hermes UK"
          headers={emptyHermesHeader}
          style={{ textTransform: 'none' }}
        >
          Hermes Unshipped
        </CSVLink>
      </Grid>

      <Grid item>
        <CSVLink
          data={receipts.map(mapShopReceiptToHermesCsv)}
          filename={`hermes-${account.loginName.toLowerCase()}-${dayjs().format('YYYY-MM-DD')}.csv`}
          className={linkSty}
          title="Download CSV for all items on this page for import to Hermes UK"
          headers={emptyHermesHeader}
          style={{ textTransform: 'none' }}
        >
          Hermes All
        </CSVLink>
      </Grid>

      <Grid item>
        <TextField
          id="searchReceipt"
          label="Search by Receipt ID"
          value={searchReceiptId}
          onChange={(e) => {
            const onlyNums = e.target.value.replace(/[^0-9]/g, '');
            setSearchReceiptId(onlyNums);
          }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              // Do code here
              onClickSearchReceiptId(searchReceiptId);
              ev.preventDefault();
            }
          }}
        />
      </Grid>
    </Grid>
  );
}
