import { IShopReceipt } from '@pm/etsy-ts';
import { Account } from '@pm/types';
import { useEffect, useState } from 'react';
import getEtsyReceipt from '../logic/firebase/getEtsyReceipt';

const defaultData: IShopReceipt | undefined = undefined;

export default function useEtsyReceipt(
  account: Account | undefined,
  receiptId: string,
): IShopReceipt | undefined {
  const [data, setData] = useState<IShopReceipt | undefined>(defaultData);

  useEffect(() => {
    const fetchData = async () => {
      const receiptIdNumber = parseInt(receiptId, 10);
      if (!account || Number.isNaN(receiptIdNumber)) {
        setData(defaultData);
        return;
      }

      // get data
      const result = await getEtsyReceipt(account.loginName, receiptIdNumber);
      setData(result || defaultData);
    };
    fetchData();
  }, [account, receiptId]);
  return data;
}
