/**
 * return true if src includes any of the item in needles
 * @param src the string to be searched
 * @param needles the array of strings to search
 */
export function includes(src: string, needles: string[], isCaseInsensitive: boolean = false) {
  const source = isCaseInsensitive ? src.toLowerCase() : src;
  const newNeedles = needles.map((needle) => (isCaseInsensitive ? needle.toLowerCase() : needle));

  return newNeedles.some((n) => source.includes(n));
}
