import { Grid } from '@mui/material';
import { Account } from '@pm/types';
import { IShopReceiptTransaction } from '@pm/etsy-ts';
import DeliveryNoteTransactionsItem from './DeliveryNoteTransactionsItem';

export interface DeliveryNoteTransactionsProps {
  account: Account;
  transactions: IShopReceiptTransaction[];
}

function getWidth(l: number) {
  if (l < 3) return 40;
  return l === 3 ? 30 : 20;
}

export default function DeliveryNoteTransactions(props: DeliveryNoteTransactionsProps) {
  const { transactions, account } = props;
  const width = `${getWidth(transactions.length)}%`;
  return (
    <Grid
      container
      sx={{ width: '100%' }}
      direction="row"
      wrap="wrap"
      justifyContent="space-around"
    >
      {transactions.map((t) => (
        <Grid
          item
          sx={{
            maxWidth: '40%',
            minWidth: '20%',
            marginLeft: '2.5%',
            marginRight: '2.5%',
            marginBottom: '2.5%',
          }}
          key={`DeliveryNoteTransactions-${t.transaction_id}`}
          style={{ width }}
        >
          <DeliveryNoteTransactionsItem transaction={t} account={account} />
        </Grid>
      ))}
    </Grid>
  );
}
