import { Avatar, IconButton } from '@mui/material';
import { signOut } from 'firebase/auth';
import { IAuth } from '../../hooks/useAuth';
import { firebaseAuth } from '../../setup/firebase';

export interface HeaderAccountProps {
  user: IAuth;
}

export default function HeaderAccount(props: HeaderAccountProps) {
  const { user } = props;

  return (
    <IconButton
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      color="inherit"
      onClick={() => signOut(firebaseAuth)}
      sx={{ marginRight: 0 }}
    >
      {user.photoURL ? (
        <Avatar
          alt={user.displayName || ''}
          src={user.photoURL}
          imgProps={{ referrerPolicy: 'no-referrer' }}
        />
      ) : (
        <Avatar>{user.displayName?.slice(0, 1)}</Avatar>
      )}
    </IconButton>
  );
}
