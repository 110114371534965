import { Button, Menu, MenuItem, useTheme } from '@mui/material';
import { Accounts } from '@pm/types';
import { useState } from 'react';

export interface IAccountMenuComponentProps {
  accounts: Accounts;
  account?: string;
  setAccount: (account: string) => void;
}

export default function AccountMenuComponent(props: IAccountMenuComponentProps) {
  const { accounts, account, setAccount } = props;
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="outlined"
        color="primary"
        sx={{
          minHeight: 37,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          borderColor: theme.palette.primary.contrastText,
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            borderColor: theme.palette.primary.contrastText,
          },
        }}
      >
        {account || 'Accounts'}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {Object.keys(accounts).map((acc) => (
          <MenuItem
            key={acc}
            onClick={() => {
              setAccount(acc);
              handleClose();
            }}
          >
            {acc}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
