// This component is responsible for the actual routing
import { useState } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import HeaderOrganism from '../components/header/HeaderOrganism';
import { IAuthState } from '../hooks/useAuth';
import useSubscribeAccounts from '../hooks/useSubscribeAccounts';
import LoginPage from '../pages/login/LoginPage';
import ReceiptPage from '../pages/Receipt/ReceiptPage';

export interface AppRouterProps {
  auth: IAuthState | null;
}

export default function AppRouter(props: AppRouterProps) {
  const { auth } = props;
  const accounts = useSubscribeAccounts(auth?.uid);
  const [account, setAccount] = useState<string | undefined>();
  const isFound = Object.entries(accounts).find(([k]) => k === account);
  const currentAccount = isFound ? isFound[1] : undefined;

  const uid = (auth && auth.uid) || '';
  const isAuthenticated = uid.length > 0;

  return (
    <Router>
      <div>
        {isAuthenticated && (
          <HeaderOrganism
            title="Etsy Product Management"
            auth={auth}
            accounts={accounts}
            account={account}
            setAccount={setAccount}
          />
        )}
        <Routes>
          {!isAuthenticated && <Route path="/" element={<LoginPage />} />}
          {!isAuthenticated && <Route path="/*" element={<Navigate to="/" />} />}
          {isAuthenticated && <Route path="/" element={<ReceiptPage account={currentAccount} />} />}
        </Routes>
      </div>
    </Router>
  );
}
