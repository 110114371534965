import { Grid, Typography } from '@mui/material';
import { defaultReturnAddress } from './DeliverySettings';

export interface DeliveryNoteAddressProps {
  returnAddress?: string[];
  deliveryAddress: string[];
}

export default function DeliveryNoteAddress(props: DeliveryNoteAddressProps) {
  const { returnAddress = defaultReturnAddress, deliveryAddress } = props;
  return (
    <Grid container direction="column" sx={{ width: '100%' }}>
      <Grid item>
        <Typography variant="subtitle2">Return Address</Typography>
      </Grid>
      <Grid item>
        {returnAddress.map((r) => (
          <Typography
            variant="body2"
            key={`DeliveryNoteAddress-rtn-${r}`}
            sx={{
              fontSize: 14,
              lineHeight: 1.1,
              textDecorationLine: 'line-through',
            }}
          >
            {r}
          </Typography>
        ))}
      </Grid>
      <Grid
        item
        sx={{
          borderTopColor: 'black',
          borderTopStyle: 'solid',
          borderTopWidth: 1,
        }}
      >
        <Typography variant="subtitle1">Delivery Address</Typography>
      </Grid>
      <Grid item>
        {deliveryAddress.map((r) => (
          <Typography
            variant="body1"
            key={`DeliveryNoteAddress-del-${r}`}
            sx={{
              fontSize: 14,
              lineHeight: 1.1,
            }}
          >
            {r.replaceAll('&#38;', '&').replaceAll('&#34;', '"').replaceAll('&#39;', "'")}
          </Typography>
        ))}
      </Grid>
    </Grid>
  );
}
