import { Grid } from '@mui/material';
import { Account } from '@pm/types';
import { IShopReceipt } from '@pm/etsy-ts';
import { useState } from 'react';
import DeliveryComponent from '../../components/delivery/DeliveryComponent';
import useEtsyReceipt from '../../hooks/useEtsyReceipt';
import useEtsyReceiptList from '../../hooks/useEtsyReceiptList';
import ReceiptHeader from './ReceiptHeader';
import ReceiptTable from './ReceiptTable';

export interface ReceiptPageProps {
  account?: Account;
}

export default function ReceiptPage(props: ReceiptPageProps) {
  const { account } = props;
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [receipt, setReceipt] = useState<IShopReceipt | undefined>();
  const [searchReceipt, setSearchReceipt] = useState<string>('');

  const receiptsData = useEtsyReceiptList(account, pageNumber, pageSize);
  const singleReceipt = useEtsyReceipt(account, searchReceipt);

  const receiptArr = singleReceipt ? [singleReceipt] : receiptsData.results;

  if (!account) return <>Please select an account</>;
  return (
    <Grid container direction="column">
      {/* Only visible when receipt selected */}
      <Grid item>
        <DeliveryComponent
          receipt={receipt}
          account={account}
          handleClose={() => setReceipt(undefined)}
        />
      </Grid>

      <Grid item>
        <ReceiptHeader
          receipts={receiptArr}
          account={account}
          onClickSearchReceiptId={setSearchReceipt}
        />
      </Grid>

      <Grid item>
        <ReceiptTable
          receipts={receiptArr}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          rowCount={receiptsData.count}
          setPageNumber={setPageNumber}
          setReceipt={setReceipt}
        />
      </Grid>
    </Grid>
  );
}
