import { CssBaseline, ThemeProvider } from '@mui/material';
import useAuth from '../hooks/useAuth';
import AppRouter from './AppRouter';
import muiTheme from './theme';

export default function App() {
  const auth = useAuth();
  const isInit = auth !== undefined;

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      {isInit ? <AppRouter auth={auth} /> : null}
    </ThemeProvider>
  );
}
