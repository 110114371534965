import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { Account } from '@pm/types';
import { IListingImage, IShopReceiptTransaction } from '@pm/etsy-ts';
import { useEtsyGetShopListingImage } from '../../hooks/useEtsyGetShopListingImage';

export interface DeliveryNoteTransactionsItemProps {
  account: Account;
  transaction: IShopReceiptTransaction;
}

export interface DeliveryNoteTransactionsItemDisplayProps {
  imgUrl: string;
  sku: string;
  variation: { [key: string]: any };
  title: string;
}

const convertTransactionToTransactionRow = (
  transaction: IShopReceiptTransaction,
  image?: IListingImage,
): DeliveryNoteTransactionsItemDisplayProps => {
  const variation = (transaction.variations || []).reduce(
    (prev, cur) => ({ ...prev, [cur.formatted_name as string]: cur.formatted_value || '' }),
    {} as Record<string, string>,
  );
  // return { imgUrl: '', sku: '', variation, title: '' };
  return {
    imgUrl: image?.url_570xN || '',
    sku: transaction.sku || '',
    variation,
    title: transaction.title || '',
  };
};

export default function DeliveryNoteTransactionsItem(props: DeliveryNoteTransactionsItemProps) {
  const { transaction, account } = props;
  const image = useEtsyGetShopListingImage(
    account,
    transaction.listing_id || undefined,
    transaction.listing_image_id || undefined,
  );
  const data = convertTransactionToTransactionRow(transaction, image);

  return (
    <Card>
      <CardMedia component="img" alt={data.title} image={data.imgUrl} title={data.title} />
      <CardContent sx={{ padding: '5px', paddingBottom: '5px !important' }}>
        <Typography gutterBottom variant="h5" sx={{ fontSize: 10, lineHeight: 1.1 }}>
          {data.sku}
        </Typography>
        {Object.keys(data.variation).map((k) => (
          <Typography
            gutterBottom
            variant="body1"
            key={`DeliveryNoteTransactionsItem-${data.variation[k].title}-${k}`}
            sx={{ fontSize: 10, lineHeight: 1.1 }}
          >
            {k}: {data.variation[k]}
          </Typography>
        ))}
      </CardContent>
    </Card>
  );
}
