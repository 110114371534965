import { Grid } from '@mui/material';

// const bannerHeightMm = 25;

export interface DeliveryNoteBannerProps {
  shop: string;
}

export default function DeliveryNoteBanner(props: DeliveryNoteBannerProps) {
  const { shop } = props;
  return (
    <Grid container justifyContent="center" style={{ height: '100%' }}>
      <Grid item style={{ height: '100%' }}>
        <img
          src={`/images/deliveryNote/${shop}.png`}
          alt="Banner"
          style={{
            height: '100%',
            maxWidth: '100%',
            textAlign: 'center',
          }}
        />
      </Grid>
    </Grid>
  );
}
